import React, { Component } from 'react';
import './App.css';
import ReactGA from 'react-ga';


class App extends Component {
    constructor (props)
    {
        super(props);
        ReactGA.initialize('UA-66371271-1');
    }

    render() {
        document.title = "MOJELD.COM"
        ReactGA.pageview(window.location.pathname + window.location.search);
        return (
            <div className="App">
                <header className="App-header">
                <a
                    className="App-link"
                    href="/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
           
                </a>
            </header>
        </div>
        );
    }
}

export default App;
